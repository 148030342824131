// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesorios-js": () => import("./../../../src/pages/accesorios.js" /* webpackChunkName: "component---src-pages-accesorios-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-detalles-compra-js": () => import("./../../../src/pages/detalles-compra.js" /* webpackChunkName: "component---src-pages-detalles-compra-js" */),
  "component---src-pages-eventos-corporativos-js": () => import("./../../../src/pages/eventos/corporativos.js" /* webpackChunkName: "component---src-pages-eventos-corporativos-js" */),
  "component---src-pages-eventos-sociales-barmitzva-js": () => import("./../../../src/pages/eventos/sociales/barmitzva.js" /* webpackChunkName: "component---src-pages-eventos-sociales-barmitzva-js" */),
  "component---src-pages-eventos-sociales-bodas-js": () => import("./../../../src/pages/eventos/sociales/bodas.js" /* webpackChunkName: "component---src-pages-eventos-sociales-bodas-js" */),
  "component---src-pages-eventos-sociales-compromiso-js": () => import("./../../../src/pages/eventos/sociales/compromiso.js" /* webpackChunkName: "component---src-pages-eventos-sociales-compromiso-js" */),
  "component---src-pages-eventos-sociales-desayunos-js": () => import("./../../../src/pages/eventos/sociales/desayunos.js" /* webpackChunkName: "component---src-pages-eventos-sociales-desayunos-js" */),
  "component---src-pages-eventos-sociales-js": () => import("./../../../src/pages/eventos/sociales.js" /* webpackChunkName: "component---src-pages-eventos-sociales-js" */),
  "component---src-pages-home-design-js": () => import("./../../../src/pages/home-design.js" /* webpackChunkName: "component---src-pages-home-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

