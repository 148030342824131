module.exports = [{
      plugin: require('../node_modules/gatsby-theme-shopify-manager/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"l-atelier-des-fleurs","accessToken":"84c244fc640925c0c962d89367fcca63"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"L'atelier Des Fleurs","short_name":"L'atelier","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2a018c2cdd0b96567313b5f2f3ce4b82"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
